<template>
  <div :id="`${tableName}-search-bar`">
    <b-form-group :id="`${tableName}-search-bar-form-group`" class="mb-0">
      <label :for="`${tableName}-search-bar-input-group`" class="search-bar-form-label">
        {{ $t('common.search_by') }}
        <template v-if="$store.getters[`app/${breakpoint}AndDown`]">{{ label }}</template>
      </label>
      <b-input-group :id="`${tableName}-search-bar-input-group`">
        <b-input-group-prepend
          v-if="(filterOptions.length > 1 && !$store.getters[`app/${breakpoint}AndDown`]) || !$store.getters[`app/${breakpoint}AndDown`]"
        >
          <b-dropdown
            :id="`${tableName}-search-bar-filter`"
            :disabled="filterOptions.length < 2 || disabled"
            :no-caret="$store.getters[`app/${breakpoint}AndDown`]"
            :toggle-class="{ 'btn-icon': $store.getters[`app/${breakpoint}AndDown`] }"
            no-flip
            variant="outline-primary"
          >
            <template #button-content>
              <feather-icon v-if="$store.getters[`app/${breakpoint}AndDown`]" icon="ChevronDownIcon" />
              <span v-else>{{ label }}</span>
            </template>
            <!-- <b-dropdown-item @click="filter('all')">{{ $t('common.all') }} </b-dropdown-item> -->
            <b-dropdown-item v-for="option in filterOptions" :key="option.value" @click="filter(option)">
              {{ option.text }}
            </b-dropdown-item>
          </b-dropdown>
          <!-- field -->
        </b-input-group-prepend>
        <!-- text -->
        <b-form-input
          :id="`${tableName}-search-bar-text`"
          v-model="text"
          :type="searchFieldType"
          :disabled="disabled"
          :placeholder="searchPlaceholder ? searchPlaceholder : $t('action.type_search')"
          class="search-bar-text"
          @keydown.enter="textChanged && search(), (textChanged = false)"
          @keyup.backspace="!text && search()"
        />
        <b-input-group-append>
          <b-button
            :id="`${tableName}-search-bar-button`"
            :class="{ 'btn-icon': $store.getters[`app/${breakpoint}AndDown`] }"
            :disabled="!text || disabled || !textChanged"
            size="sm"
            variant="outline-primary"
            @click="search(), (textChanged = false)"
          >
            <feather-icon :class="{ 'search-bar-button': $store.getters[`app/${breakpoint}AndDown`] }" icon="SearchIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'AppDataTableSearch',

  props: {
    // the table name the search is binded
    // 'offers'
    tableName: {
      type: String,
      required: true,
    },
    searchText: {
      type: String,
      default: '',
    },
    searchFieldType: {
      type: String,
      default: 'text',
    },
    // disable the search bar
    disabled: {
      type: Boolean,
      required: true,
    },
    // filter options for searching
    // [{value:'offerReference',text:'Offer reference'}]
    filterOptions: {
      type: Array,
      required: true,
    },
    // the default filter to show
    // 'offerReferece'
    defaultFilter: {
      type: String,
      default: null,
    },
    // the condensed breakpoint
    // 'sm', 'md' ...
    condensed: {
      type: String,
      default: 'lg',
    },
    searchPlaceholder: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      text: '',
      filterOption: 'all',
      textChanged: false,
    }
  },

  computed: {
    breakpoint() {
      return ['sm', 'xs', 'md', 'lg', 'xl'].indexOf(this.condensed) !== -1 ? this.condensed : 'lg'
    },

    label() {
      const label = this.filterOptions.find(option => option.value === this.filterOption)
      if (label) return label.text
      return this.$t('common.all')
    },
  },

  watch: {
    defaultFilter(val) {
      this.isEmpty(val) ? (this.filterOption = 'all') : (this.filterOption = this.defaultFilter)
    },

    text() {
      this.textChanged = true
    },
  },

  mounted() {
    this.defaultFilter && (this.filterOption = this.defaultFilter)
    this.text = this.searchText
  },

  methods: {
    search() {
      this.$emit('search', this.text)
    },

    filter(option) {
      this.filterOption = option.value
      this.text = ''
      this.$emit('filter', option.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.search-bar-form-label {
  font-size: x-small;
  font-style: italic;
}
.search-bar-text {
  min-width: 100px;
}

.search-bar-button {
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
</style>
